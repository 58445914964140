import React from "react";
import { useDarkTheme } from "../DarkThemeContext";

const Policies = ({ data }) => {
  const { isDarkTheme } = useDarkTheme();
  const Policiestyles = {
    backgroundColor: isDarkTheme ? "#f6f6f6" : "#000",
    color: isDarkTheme ? "#000" : "#fff",
  };

  const headingStyles = {
    color: isDarkTheme ? "#000" : "#fff",
  };
  return (
    <div id="policies" style={Policiestyles}>
      <div className="container">
        <div className="section-title text-center">
          <h2 style={headingStyles}>Our Policies and Provisions</h2>
          <div className="row">
          <div className="col-md-6">
          <div className="custom-list">
        <h3 style={headingStyles} >Our Policies</h3><br></br>
        <p ><strong>Safety and Security of Students: </strong>
        To ensure the safety and security of our students, Squadron Academy has established a dedicated grievance redressal mechanism. We adhere to stringent safety protocols, providing all training sessions with the requisite safety gear and high-quality equipment. The continuous oversight of our experienced instructors ensures that students are closely monitored throughout their training, and they are not granted permission to leave the training premises during scheduled sessions.
       </p>
       <hr />
         <p><strong>Emergency Handling: </strong>
                At Squadron Academy, we prioritize emergency preparedness as a cornerstone of our operations. Our highly trained team includes dedicated first-aid responders and fire-fighting professionals who stand ready to respond promptly to any student or environmental emergencies. Our unwavering commitment to effective emergency management underscores our dedication to the well-being of our students.
              </p>
              <hr />
              <p><strong>Dealing with Disobedience and Suggestiveness: </strong>
                From the outset of their training, we conduct comprehensive psychological assessments of each cadet. This discerning approach enables us to customize our training methodologies to align with the individual traits and characteristics of our cadets, thereby effectively addressing issues related to disobedience and suggestiveness with a nuanced understanding of each cadet's unique profile.
              </p>
              <hr />
              <p><strong>Tackling Bullying and Raging Cases: </strong>
                Squadron Academy upholds a stringent code of conduct firmly rooted in military principles that promote discipline and equality among students. Our meticulously organized training programs foster a strong sense of unity, substantially reducing the incidence of bullying. In instances where such issues do arise, our dedicated grievance redressal team is equipped to take swift and appropriate action.
              </p>
              <hr />
              <p><strong>Ensuring Safety:</strong>
                Our commitment to safety extends to all cadets, regardless of gender. We maintain an exceptionally low student-trainer ratio of 1:30, ensuring personalized attention. Female cadets are exclusively instructed by female instructors, and we enforce strict policies to prohibit any inappropriate interactions. Furthermore, both female and male cadets are encouraged to report any instances of discomfort directly to the highest authorities within our organization, guaranteeing a safe and inclusive environment for all.
              </p>
              <hr />
              <p><strong>Quality of Training: </strong>
                Squadron Academy boasts an exceptional faculty comprising certified professionals and experts across various domains. Our team includes trained professionals in first-aid, firefighting, mountaineering, martial arts, and shooting. Many of our instructors are distinguished NCC alumni with extensive experience, having participated in prestigious national-level camps such as the Republic Day camp and the All India Thal Sainik camp. This unparalleled opportunity allows our students to receive training under the guidance of accomplished individuals, assuring them of the highest caliber of expertise and direction in their pursuits.
              </p>
              <hr />
       </div>
        </div>
        <div className="col-md-6">
          <div className="custom-list">
        <h3 style={headingStyles}>Our Provisions</h3><br></br>
                <p>
                <strong>Certification of Personality Development:</strong> Upon successful completion of their training, cadets will be conferred with a Personality Development Certificate. This certification serves as a significant advantage, augmenting their prospects during job interviews, and providing them with a competitive edge in the employment market.
              </p>
              <hr />
              <p>
              <strong> Ranking Based on Performance:</strong> Cadets will be subject to a comprehensive evaluation of their performance throughout the training program. Based on their achievements, they will be awarded ranks that reflect their dedication, commitment, and competence.
              </p>
              <hr />
              <p>
              <strong> Sponsorship for Advanced and Adventure Courses:</strong> Exceptional cadets who attain top ranks will be offered the opportunity to partake in advanced courses, including but not limited to climbing, rappelling, mountaineering, and more. These sponsored courses are designed to enhance their skillset and personal growth.
              </p>
              <hr />
              <p>
              <strong> Employment Opportunities:</strong> Following the successful completion of their training, cadets will be provided with exclusive employment opportunities within the Squadron Academy. This employment avenue ensures a seamless transition from training to a fulfilling career.
              </p>
              <hr />
              <p>
               <strong> Leadership and Versatility:</strong> The paramount advantage of enrolling in this course is the transformation it confers upon cadets. Graduates emerge as leaders in their chosen fields, possessing a versatile skillset that sets them apart as competent and well-rounded citizens. This comprehensive development is invaluable in fostering personal and professional success.
              </p>
              <hr />
        </div>
        </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Policies;
