import React from "react";
import { useDarkTheme } from "../DarkThemeContext";

const Testimonials = ({ data }) => {
  const { isDarkTheme } = useDarkTheme();
  const testimonialStyles = {
    backgroundColor: isDarkTheme ? "#f6f6f6" : "#000",
    color: isDarkTheme ? "#000" : "#fff",
  };

  const headingStyles = {
    color: isDarkTheme ? "#000" : "#fff",
  };

  const renderTestimonials = () => {
    if (!data) return <p>Loading...</p>;

    // Group testimonials into rows of three
    const testimonialsRows = [];
    for (let i = 0; i < data.length; i += 3) {
      const row = (
        <div className="row" key={i}>
          {data.slice(i, i + 3).map((testimonial, index) => (
            <div key={index} className="col-md-4">
              <div className="testimonial">
                <div className="testimonial-content">
                  <p>"{testimonial.text}"</p>
                  <div className="testimonial-meta" style={headingStyles}>
                    - {testimonial.name}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
      testimonialsRows.push(row);
    }

    return testimonialsRows;
  };

  return (
    <div id="testimonials" style={testimonialStyles}>
      <div className="container">
        <div className="section-title text-center">
          <h2 style={headingStyles}>HEAR FROM OUR PARENTS AND STUDENTS</h2>
        </div>
        {renderTestimonials()}
      </div>
    </div>
  );
};

export default Testimonials;
