import React, { useState, useEffect, useMemo } from "react";

const Header = (props) => {
  const images = useMemo(() => [
    "bg6.webp",
    "bg1.webp",
    "bg7.webp",
    "bg3.webp",
    "bg4.webp",
    "bg5.webp",

  ], []);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [images]);

  const currentImageUrl = `/img/slider/${images[currentImageIndex]}`;

  return (
    <header id="header">
      <div
        className="intro"
        style={{
          backgroundImage: `url(${currentImageUrl})`,
        }}
      >
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>WE ARE</h1>
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
