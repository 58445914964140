import React, { useState } from "react";
import { useDarkTheme } from "../DarkThemeContext";

export const Services = (props) => {
  const { isDarkTheme } = useDarkTheme();
  const [selectedService, setSelectedService] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const serviceStyles = {
    background: isDarkTheme
      ? "linear-gradient(to right, #5ca9fb 0%, #6B8E23 100%)"
      : "#000",
    color: "#fff",
  };

  const iconStyles = {
    fontSize: "42px",
    width: "120px",
    height: "120px",
    padding: "40px 0",
    borderRadius: "50%",
    boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.05)",
    cursor: "pointer",
  };

  const popupStyles = {
    display: isPopupOpen ? "block" : "none",
    background: "rgba(76, 175, 80, 0.6)",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    marginTop: "15px",
    fontSize: "15px",
    textAlign: "left",
    paddingLeft: "20px", 
  };

  const renderServicesInRows = () => {
    if (!props.data) {
      return "loading";
    }

    const servicesRows = [];
    const numServicesPerRow = 3;

    for (let i = 0; i < props.data.length; i += numServicesPerRow) {
      const rowServices = props.data.slice(i, i + numServicesPerRow);

      servicesRows.push(
        <div className="row" key={`row-${i}`}>
          {rowServices.map((d, j) => (
            <div
              key={`${d.name}-${j}`}
              className={`col-md-4 ${
                selectedService === i + j ? "selected-service" : ""
              }`}
              onClick={() => handleServiceClick(i + j)}
            >
              <i className={d.icon} style={iconStyles}></i>
              <div className="service-desc">
                <h3>{d.name}</h3>
                <p dangerouslySetInnerHTML={{ __html: d.text }} />
                {i === 0 && d.courseDetails && (
                  <button
                    onClick={() => handleShowCourseDetailsClick(i + j)}
                    className="link-button"
                  >
                    {selectedService === i + j && isPopupOpen
                      ? "Hide Course Details"
                      : "Show Course Details"}
                  </button>
                )}
                {selectedService === i + j && d.courseDetails && (
                  <div className="popup-container" style={popupStyles}>
                    <h3 style={{ fontWeight: "bold", textAlign: "center" }}>Course Details</h3>
                    <ul>
                      {d.courseDetails.map((detail, index) => (
                        <li key={index}>{detail}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    }
    return servicesRows;
  };

  const handleServiceClick = (index) => {
    if (index === selectedService) {
      setSelectedService(null);
      setPopupOpen(false);
    } else {
      setSelectedService(index);
      setPopupOpen(true);
    }
  };

  const handleShowCourseDetailsClick = (index) => {
    if (selectedService === index && isPopupOpen) {
      setSelectedService(null);
      setPopupOpen(false);
    } else {
      setSelectedService(index);
      setPopupOpen(true);
    }
  };

  return (
    <section id="services" className="text-center" style={serviceStyles}>
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            At Squadron Academy, we are committed to providing an exceptional
            learning experience that goes beyond the conventional. Our range of
            services is designed to nurture well-rounded individuals and prepare
            them for the challenges of the modern world. Explore the unique
            offerings that make Squadron Academy a place where personal growth
            and excellence intersect.
          </p>
        </div>
        {renderServicesInRows()}
      </div>
    </section>
  );
};
