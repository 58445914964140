import React, { useEffect } from "react";
import { useDarkTheme } from "../DarkThemeContext";

const Navigation = (props) => {
  const { isDarkTheme, toggleDarkTheme } = useDarkTheme();

  useEffect(() => {
  }, [isDarkTheme]);

  const handleButtonClick = () => {
    toggleDarkTheme();
  };

  const menuClass = isDarkTheme ? "navbar-default dark-mode" : "navbar-default light-mode";

  return (
    <nav id="menu" className={`navbar navbar-default ${menuClass} navbar-fixed-top`}>
      <div className="container">
        <div className="navbar-header">
        <img
          src="logo.png"
          alt="Logo"
          className="logo"
          loading="lazy"
          style={{
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            marginRight: '25px',
            float: 'left',
          }}
        />
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            Squadron Academy
          </a>{" "}
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <NavItem href="#features" text="Features" />
            <NavItem href="#about" text="About" />
            <NavItem href="#services" text="Services" />
            <NavItem href="#portfolio" text="Gallery" />
            <NavItem href="#testimonials" text="Testimonials" />
            <NavItem href="#team" text="Team" />
            <NavItem href="#policies" text="Policies" />
            <NavItem href="#contact" text="Contact" />
            <li>
              <button
                className="theme-button"
                style={{ marginLeft: '22px', marginTop: '5px' }}
                onClick={handleButtonClick}
              >
                {isDarkTheme ? "☀️" : "🌙"}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const NavItem = ({ href, text }) => (
  <li>
    <a href={href} className="page-scroll">
      {text}
    </a>
  </li>
);

export default Navigation;
