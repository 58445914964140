import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { useDarkTheme } from "../DarkThemeContext";
import CopyToClipboard from "react-copy-to-clipboard";

const initialState = {
  name: "",
  email: "",
  phone: "",
  message: "",
  role: "Student",
};

const Contact = (props) => {
  const { isDarkTheme } = useDarkTheme();
  
  const contactStyles = {
    background: isDarkTheme
      ? "linear-gradient(to right, #5ca9fb 0%, #6B8E23 100%)"
      : "#000",
    color: "#fff",
  };

  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [isPhoneCopied, setIsPhoneCopied] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const clearForm = () => setFormData(initialState);

  const handlePhoneCopy = () => {
    setIsPhoneCopied(true);

    // Reset the copy indication after a short delay
    setTimeout(() => {
      setIsPhoneCopied(false);
    }, 2000); // Adjust the duration as needed
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm("service_i6os4iu", "template_hkg4jss", e.target, "i7Kt5UJ_6eHeWivcf")
      .then(
        (result) => {
          console.log(result.text);
          clearForm();
          setConfirmation(
            "Thank you for connecting with Squadron Academy. We'll get back to you as soon as possible."
          );
          setLoading(false);
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
        }
      );
  };

  useEffect(() => {
    const formTimeout = setTimeout(() => {
      setConfirmation(null);
      setShowForm(true);
    }, 5000);

    return () => clearTimeout(formTimeout);
  }, [confirmation]);

  return (
    <div>
      <div id="contact" style={contactStyles}>
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email, and we
                  will get back to you as soon as possible.
                </p>
              </div>
              {loading ? (
                <p>Sending your message...</p>
              ) : confirmation ? (
                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                  {confirmation}
                </p>
              ) : showForm ? (
                <form name="sentMessage" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          required
                          pattern="[A-Za-z\s]{1,50}"
                          maxLength="20"
                          onChange={handleChange}
                          value={formData.name}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
                          onChange={handleChange}
                          value={formData.email}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="tel"
                          id="phone"
                          name="phone"
                          className="form-control"
                          placeholder="Phone Number"
                          required
                          pattern="\d{10}"
                          onChange={handleChange}
                          value={formData.phone}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" style={{ display: "flex", flexDirection: "row" }}>
                        <label>Inquiry Type : </label>
                        <label style={{ marginLeft: "10px" }}>
                          <input
                            type="radio"
                            id="student"
                            name="role"
                            value="Student"
                            onChange={handleChange}
                            checked={formData.role === "Student"}
                          />
                          Student
                        </label>
                        <label style={{ marginLeft: "10px" }}>
                          <input
                            type="radio"
                            name="role"
                            id="school"
                            value="School"
                            onChange={handleChange}
                            checked={formData.role === "School"}
                          />
                          School
                        </label>
                        <label style={{ marginLeft: "10px" }}>
                          <input
                            type="radio"
                            name="role"
                            id="college"
                            value="College"
                            onChange={handleChange}
                            checked={formData.role === "College"}
                          />
                          College
                        </label>
                        <label style={{ marginLeft: "10px" }}>
                          <input
                            type="radio"
                            name="role"
                            value="Company"
                            onChange={handleChange}
                            checked={formData.role === "Company"}
                          />
                          Company
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="8"
                      placeholder="Message"
                      required
                      maxLength={200}
                      onChange={handleChange}
                      value={formData.message}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                </form>
              ) : null}
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? (
                  <CopyToClipboard text={props.data.phone} onCopy={handlePhoneCopy}>
                    <span style={{ cursor: "pointer" }}>
                      {isPhoneCopied ? "Copied!" : props.data.phone}
                    </span>
                  </CopyToClipboard>
                ) : (
                  "loading"
                )}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={`mailto:${props.data ? props.data.envelope : "/"}`}>
                      <i className="fa fa-envelope"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? `https://www.youtube.com/${props.data.youtube}` : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? `https://www.instagram.com/${props.data.instagram}` : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? `https://www.facebook.com/${props.data.facebook}` : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2023 Squadron Academy</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
