import React from "react";
import { useDarkTheme } from "../DarkThemeContext";

const Team = ({ data }) => {
  const { isDarkTheme } = useDarkTheme();
  
  const sectionClass = isDarkTheme ? "light-mode" : "dark-mode";
  const teamClass = isDarkTheme ? "light-mode-team" : "dark-mode-team";
  const teamTitleClass = isDarkTheme ? "light-mode-title" : "dark-mode-title";
  
  const headingStyles = {
    color: isDarkTheme ? "#000" : "#fff",
  };

  if (!data || data.length === 0) {
    return (
      <div className={`no-team-data ${sectionClass}`}>
        No team member data available.
      </div>
    );
  }

  const groupSize = 3;
  const teams = [];
  
  for (let i = 0; i < data.length; i += groupSize) {
    teams.push(data.slice(i, i + groupSize));
  }

  return (
    <div id="team" className={`text-center ${sectionClass}`}>
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2 className={`team-title ${teamTitleClass}`} style={headingStyles}>
            Meet the Team
          </h2>
          <p>
            At Squadron Academy, our strength lies in our dedication and experienced team. 
            We believe that the quality of education and the guidance provided to our cadets are a reflection of the expertise and commitment of our instructors. 
            Get to know the individuals who are driving our mission of nurturing young minds.
          </p>
        </div>
        {teams.map((team, rowIndex) => (
          <div key={rowIndex} className={`row ${teamClass}`}>
            {team.map((member, i) => (
              <div
                key={`${member.name}-${i}`}
                className={`col-md-4 col-sm-6 team ${teamClass}`}
              >
                <div className={`thumbnail ${teamClass}`}>
                  <img src={member.img} alt="..." className="team-img" />
                  <div className="caption">
                    <h4 className={teamClass} style={headingStyles}>
                      {member.name}
                    </h4>
                    <h5>
                      <p className={teamClass}>{member.job}</p>
                      <p className={teamClass}>{member.job1}</p>
                    </h5>
                    <p className={teamClass} style={headingStyles}>
                      {member.bio}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
