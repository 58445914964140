import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";
import { useDarkTheme } from "../DarkThemeContext";

const About = (props) => {
  const { isDarkTheme } = useDarkTheme();
  const formattedText = props.data ? props.data.paragraph : "Loading...";
  const themeClass = isDarkTheme ? "light-mode" : "dark-mode";

  return (
    <section className={themeClass}>
      <div id="about">
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <img src="img/about.webp" className="img-responsive" alt="about" loading="lazy" />
            </Col>
            <Col xs={12} md={6}>
              <div className="about-text">
                <h2>About Us</h2>
                <p dangerouslySetInnerHTML={{ __html: formattedText }} />
                <h3>Why To Choose Us?</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data &&
                        props.data.Why.map((d, i) => <li key={`Why-${i}`}>{d}</li>)}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data &&
                        props.data.Why2.map((d, i) => <li key={`Why2-${i}`}>{d}</li>)}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={`count-section ${themeClass}`} style={{ marginTop: "-90px" }}>
        <Container>
          <Row>
            <Col lg={3} md={3} className="text-center">
              <div className="about__counter">
                <div className="single__counter">
                  <span className={`counter ${themeClass}`}>
                    <CountUp start={0} end={12} duration={2} suffix="+" />
                  </span>
                  <p className={`counter__title ${themeClass}`}>Allied Schools</p>
                </div>
              </div>
            </Col>

            <Col lg={3} md={3} className="text-center">
              <div className="about__counter">
                <div className="single__counter">
                  <span className={`counter ${themeClass}`}>
                    <CountUp start={0} end={2000} duration={2} separator="," suffix="+" />
                  </span>
                  <p className={`counter__title ${themeClass}`}>Enrolled Students</p>
                </div>
              </div>
            </Col>

            <Col lg={3} md={3} className="text-center">
              <div className="about__counter">
                <div className="single__counter">
                  <span className={`counter ${themeClass}`}>
                    <CountUp start={0} end={23} duration={2} suffix="+" />
                  </span>
                  <p className={`counter__title ${themeClass}`}>Faculty</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default About;
