import React from "react";
import { useDarkTheme } from "../DarkThemeContext";

const Features = (props) => {
  const { isDarkTheme } = useDarkTheme();

  const featureStyles = {
    backgroundColor: isDarkTheme ? "#f6f6f6" : "#000",
    color: isDarkTheme ? "#000" : "#fff",
  };

  const headingStyles = {
    color: isDarkTheme ? "#000" : "#fff",
  };

  const chunkArray = (array, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  const featureChunks = props.data ? chunkArray(props.data, 3) : null;

  return (
    <section id="features" className="text-center" style={featureStyles}>
      <div className="container">
        <div className="col-md-10 offset-md-1 section-title">
          <h2 style={headingStyles}>Features</h2>
        </div>
        {featureChunks ? (
          <React.Fragment>
            {featureChunks.map((chunk, rowIndex) => (
              <div key={`row-${rowIndex}`} className="row">
                {chunk.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="col-md-4">
                    <i className={d.icon} alt={`Icon for ${d.title}`}></i>
                    <h3 style={headingStyles}>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))}
              </div>
            ))}
          </React.Fragment>
        ) : (
          <p style={headingStyles}>Loading...</p>
        )}
      </div>
    </section>
  );
};

export default Features;
