import React from "react";
import { Image } from "./image";
import { useDarkTheme } from "../DarkThemeContext";

const Gallery = ({ data }) => {
  const { isDarkTheme } = useDarkTheme();

  const galleryStyles = {
    background: isDarkTheme ? "#fff" : "#000",
    color: isDarkTheme ? "#000" : "#fff",
  };

  return (
    <div
      id="portfolio"
      className={`text-center ${isDarkTheme ? "light-mode" : "dark-mode"}`}
      style={galleryStyles}
    >
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
            We're excited to share with you a glimpse of what we have to offer. Below, you'll find a collection of images showcasing various aspects of our academy. However, if you're eager to explore further and dive deeper into our activities, programs, and daily lives, we encourage you to visit and follow our social media handles.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {data ? (
              data.map((item, index) => (
                <div key={index} className="col-sm-6 col-md-4 col-lg-4">
                  <Image
                    title={item.title}
                    largeImage={item.largeImage}
                    smallImage={item.smallImage}
                    alt="Image"
                  />
                </div>
              ))
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
